import React, { useEffect } from 'react';

const YandexAd = () => {
  // useEffect(() => {
  //   console.log('Загружаем скрипт Яндекса на main.jsx...');

  //   const script = document.createElement('script');
  //   script.src = 'https://yandex.ru/rtb/js/async.js';
  //   script.async = true;
  //   document.body.appendChild(script);

  //   script.onload = () => {
  //     console.log('Яндекс реклама загружена на main.jsx!');
  //     if (window.Ya) {
  //       console.log('Объект Ya существует на window');
  //       window.yaContextCb.push(() => {
  //         console.log('Запускаем рендеринг рекламы на main.jsx');
  //         window.Ya.Context.AdvManager.render({
  //           blockId: "R-A-14191515-2",
  //           renderTo: "yandex_rtb_R-A-14191515-2",
  //         });
  //       });
  //     } else {
  //       console.log('Объект Ya не был найден в window');
  //     }
  //   };

  //   script.onerror = (error) => {
  //     console.log('Ошибка при загрузке скрипта Яндекса', error);
  //   };

  //   // Очистка после удаления компонента
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    
    <div id="yandex_rtb_R-A-14191515-2" style={{ width: '100%' }}></div>
  );
};

export default YandexAd;
